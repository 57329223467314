require('typeface-overpass');
import '../styles/style.css';

if (module.hot) {
    module.hot.accept();
};

var req = require.context("../../assets/images", true, /\.(jpe?g|png|gif)$/);
req.keys().forEach(function(key){
  req(key);
});
